import {
    LOAD_STATUS_PENDING,
    LOAD_STATUS_STALE,
    CLIENT_SIDE_PAGINATION_LIMIT,
} from '../../../constants';
import { BaseAction } from '../../../types';
import {
    CLEAR_JOB_LIST,
    LIST_JOBS,
    REFRESH_STATE,
    SET_JOB_SEARCH_TERM,
    CREATE_OR_UPDATE_JOB,
    LOAD_EVENTS,
    LOAD_POSTS,
    DELETE_POSTS,
    CLEAR_EVENT_ACTIONS,
    DOWNLOAD_POST_ATTACHMENT,
    CLEAR_EVENTS,
    CLEAR_POSTS,
} from '../../../types/actionTypes';
import {
    completeReducer,
    failureReducer,
    mappedReducer,
    pendingReducer,
    staleReducer,
    typeComplete,
    typeFail,
    typePending,
} from '../../../utils';
import { Job, JobError, JobState } from '../types';

const INITIAL_DATA_STATE = {
    items: [],
    count: 0,
    status: LOAD_STATUS_PENDING,
    error: null,
};

export const INITIAL_STATE: JobState = {
    status: LOAD_STATUS_PENDING,
    error: null,
    jobs: {},
    searchTerm: '',
    offset: 0,
    limit: CLIENT_SIDE_PAGINATION_LIMIT,
    eventsState: INITIAL_DATA_STATE,
    postsState: INITIAL_DATA_STATE,
    eventActionState: {
        status: LOAD_STATUS_STALE,
        error: null,
        shouldReload: false,
    },
};

function setJobSearchTermReducer(
    state: JobState,
    action: BaseAction<{ searchTerm: string; offset: number; limit: number }>,
): JobState {
    const { searchTerm, offset, limit } = action.payload;
    return completeReducer({
        ...state,
        searchTerm,
        offset,
        limit,
    });
}

const createOrUpdateJob = (state: JobState, action: BaseAction<Job>) => {
    const { id } = action.payload;
    if (!id) {
        return state;
    }
    const job = state.jobs[id] ?? {};
    return {
        ...state,
        jobs: {
            ...state.jobs,
            [id]: {
                ...job,
                ...action.payload,
            },
        },
    };
};

const listJobsPendingReducer = (state: JobState): JobState => pendingReducer({ ...state });

const listJobsSuccessReducer = (state: JobState, actions: BaseAction) =>
    completeReducer({
        ...state,
        jobs: Object.fromEntries(actions.payload.map((job: Job) => [job.id, job])),
    });

function listJobsFailReducer(state: JobState, action: BaseAction<JobError>) {
    const { error } = action.payload;
    return failureReducer({ ...state, error });
}
const resetJobStateReducer = (_state: JobState, _action: BaseAction) => ({ ...INITIAL_STATE });

const clearJobListReducer = (_state: JobState, _action: BaseAction) => ({ ...INITIAL_STATE });

const listEventsPendingReducer = (state: JobState): JobState => ({
    ...state,
    eventsState: pendingReducer(state.eventsState),
});

const listEventsSuccessReducer = (state: JobState, actions: BaseAction) => ({
    ...state,
    eventsState: completeReducer({
        ...state.eventsState,
        items: actions.payload.Event,
        count: actions.payload.count,
    }),
});

const listEventsFailReducer = (state: JobState, action: BaseAction<JobError>): JobState => ({
    ...state,
    postsState: failureReducer({ ...state.postsState, error: action.payload.error }),
});

const clearEventsReducer = (state: JobState): JobState => ({
    ...state,
    eventsState: INITIAL_DATA_STATE,
});

const listPostsPendingReducer = (state: JobState): JobState => ({
    ...state,
    postsState: pendingReducer(state.postsState),
});

const listPostsSuccessReducer = (state: JobState, actions: BaseAction) => ({
    ...state,
    postsState: completeReducer({
        ...state.postsState,
        items: actions.payload.Post,
        count: actions.payload.count,
    }),
});

const listPostsFailReducer = (state: JobState, action: BaseAction<JobError>): JobState => ({
    ...state,
    postsState: failureReducer({ ...state.postsState, error: action.payload.error }),
});

const clearPostsReducer = (state: JobState): JobState => ({
    ...state,
    postsState: INITIAL_DATA_STATE,
});

const eventActionPendingReducer = (state: JobState): JobState => ({
    ...state,
    eventActionState: pendingReducer(state.eventActionState),
});

const eventActionSuccessReducer = (state: JobState, actions: BaseAction) => ({
    ...state,
    eventActionState: completeReducer(actions.payload),
});

const eventActionFailReducer = (state: JobState, action: BaseAction<JobError>): JobState => ({
    ...state,
    eventActionState: failureReducer({ ...state.eventActionState, error: action.payload.error }),
});

const clearEventActionsReducer = (state: JobState): JobState => ({
    ...state,
    eventActionState: staleReducer({ shouldReload: false }),
});

export const reducer = mappedReducer(INITIAL_STATE, {
    [typePending(LIST_JOBS)]: listJobsPendingReducer,
    [typeComplete(LIST_JOBS)]: listJobsSuccessReducer,
    [typeFail(LIST_JOBS)]: listJobsFailReducer,
    [typeComplete(SET_JOB_SEARCH_TERM)]: setJobSearchTermReducer,
    [typeComplete(CLEAR_JOB_LIST)]: clearJobListReducer,
    [CREATE_OR_UPDATE_JOB]: createOrUpdateJob,
    [REFRESH_STATE]: resetJobStateReducer,
    [typePending(LOAD_EVENTS)]: listEventsPendingReducer,
    [typeComplete(LOAD_EVENTS)]: listEventsSuccessReducer,
    [typeFail(LOAD_EVENTS)]: listEventsFailReducer,
    [typeComplete(CLEAR_EVENTS)]: clearEventsReducer,
    [typePending(LOAD_POSTS)]: listPostsPendingReducer,
    [typeComplete(LOAD_POSTS)]: listPostsSuccessReducer,
    [typeFail(LOAD_POSTS)]: listPostsFailReducer,
    [typeComplete(CLEAR_POSTS)]: clearPostsReducer,
    [typePending(DELETE_POSTS)]: eventActionPendingReducer,
    [typeComplete(DELETE_POSTS)]: eventActionSuccessReducer,
    [typeFail(DELETE_POSTS)]: eventActionFailReducer,
    [typePending(DOWNLOAD_POST_ATTACHMENT)]: eventActionPendingReducer,
    [typeComplete(DOWNLOAD_POST_ATTACHMENT)]: eventActionSuccessReducer,
    [typeFail(DOWNLOAD_POST_ATTACHMENT)]: eventActionFailReducer,
    [CLEAR_EVENT_ACTIONS]: clearEventActionsReducer,
});
